/* eslint-disable */
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  Dropdown, Stack
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import _, { lte } from "lodash";
import React, { useState, useEffect, useCallback, useRef } from "react";
import Select from "react-select";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';


import Offcanvas from 'react-bootstrap/Offcanvas';
import AddNote from '../crm-list/leads/AddNote';
import AddMail from '../crm-list/leads/AddMail';
import LeadTabs from '../crm-list/leads/tab-components/LeadTabs'
import "./CRMList.css";
import DeleteModal from "../../../shared/DeleteModal";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFilteredLeadDetails,
  fetchCityDropdown,
  fetchProductDropdown,
  fetchSourceDropdown,
  fetchAllLeadDetails,
  fetchNewLeadDetails,
  fetchEngagedLeadDetails,
  fetchDealLeadDetails,
  fetchQuoteLeadDetails,
  fetchWonLeadDetails,
  fetchDroppedLeadDetails,
  deleteLeads,
  exportLeadDetails,
  fetchContactsList,
  fetchProspectsList,
  fetchContactDetails,
  moveContactsProspectsLeads,
  fetchAllLeadTypeDetails
} from "../../../../redux/actions/crmActions";
import AddTask from "./leads/AddTask";
import { useNavigate } from "react-router";
import * as types from "../../../../constants/ActionTypes";


const CRMListFilters = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setLeadListFromActions, pagename, contactsCP, setContacts, clearDropdown,
    setTimeFilter, handleChangeCustomDate, isSearchActive, searchField, customDate, sort, source, city,
    endDate, startDate, time, setSearchField, exportDoc, setSort, setSource, setStartDate, setEndDate, deleteMultipleLeads } = props;

  const Leadlist = props.leadList;
  const authToken = props.authToken;


  const userType = localStorage.getItem("userType") ? localStorage.getItem("userType").slice(1, -1) : "";

  const productDropdown = useSelector(
    (state) => state.crmLeads.productDropdown
  );
  const sourceDropdown = useSelector((state) => state.crmLeads.sourceDropdown);
  const cityDropdown = useSelector((state) => state.crmLeads.cityDropdown);
  const filterDropdown = useSelector((state) => state.crmLeads.filterDropdown);
  //const stagesList = useSelector((state) => state.crmLeads.stagesList);
  const searchRef = useRef();


  const sortOptions = [
    { value: "ASC", label: "Sort by Date(ASC)" },
    { value: "DESC", label: "Sort by Date(DESC)" },
  ];

  const filters = [
    // { value: "0", label: "Products" },
    { value: "1", label: "Source" },
    //{ value: "2", label: "City" },
    { value: "3", label: "Choose Time" },
    // { value: "4", label: "User Name" },
  ];

  const [selectedFilter, setSelectedFilter] = useState([]);

  const [leadAction, setLeadAction] = useState("");
  const [showOffcanvas, setShowOffcanvas] = useState(false);


  const [deleteDialog, setDeleteDialog] = useState(false);
  const [offcanvasClose, setOffcanvasClose] = useState(true);

  const [isLead, setIsLead] = useState(false);
  const [isProspect, setIsProspect] = useState(false);
  const [isContact, setIsContact] = useState(false);

  // const handleChange = (filter, filterType) => {
  //   //console.log(filter);
  //   //dispatch(fetchAllLeadDetails(authToken, filterType, filter.value));
  // };

  //console.log("selectedFilter",selectedFilter);
  // console.log(
  //   "value.value",
  //   selectedFilter.some((f) => f["value"] == 3)
  // );



  // console.log("companyName>>", companyName);
  // console.log("sourceDropdown>>",sourceDropdown);
  // console.log("cityDropdown>>",cityDropdown);



  /* useEffect(()=> {
    dispatch({ type: types.CLEAR_ALL_LEAD_TYPE_DETAILS});
  },[]); */

  /*  const setTimeFilter = (val) => {
     console.log("setTimeFilter val", val);
     setTime(null);
     setCustomDate(false);
     setStartDate(null);
     setEndDate(null);
     if (val === 1) {
       setTime(1);
       document.getElementById('dropdown-autoclose-outside').click();
     } else if (val === 2) {
       setTime(2);
       document.getElementById('dropdown-autoclose-outside').click();
     } else if (val === 3) {
       setTime(3);
       document.getElementById('dropdown-autoclose-outside').click();
     }
     else if (val === 4) {
       setCustomDate(true)
     } else {
       setTime(null);
     }
     //setSettingsVal([1]);
   } */

  const getChars = (str) => {
    const firstLetters = str.split(' ').slice(0,2).map(word => word[0]).join('').toUpperCase();
    return firstLetters;
  }

  const doLeadAction = (e, val) => {
    setLeadAction("");
    setShowOffcanvas(false);
    if (val === 'task') {
      setLeadAction(val);
      setShowOffcanvas(true);
    } else if (val === 'note') {
      setLeadAction(val);
      setShowOffcanvas(true);
    }
    else if (val === 'delete') {
      setLeadAction(val);
      setDeleteDialog(true);
    } else if (val === 'mail') {
      setLeadAction(val);
      setShowOffcanvas(true);
    }
  }

  const handleOffcanvasClose = () => {
    setOffcanvasClose(false);
    setShowOffcanvas(false);
    setLeadListFromActions();
  }

  const confirmDeleteLeads = () => {
    setDeleteDialog(false);
    let arr = props.leadList.contact_ids.map((item) => {
      return item.contact_id;
    });

    deleteMultipleLeads(arr);
    // props.leadList.contact_ids.map(item => {
    //   // console.log("del arr");
    //   //  props.handleCheck(item);
    //   props.setLeadListFromActions();
    // });
  }


  const handleClose = () => setShowOffcanvas(false);

  const searchSubmitHandler = (e, searchKey, pageType) => {
    searchRef.current.value = searchKey;
    props.searchHandler(e, searchKey, pageType);
  }


  const viewComponent = (e) => {
    setContacts([]);

    if (e !== "" && e.target.value === 'leads') {
      navigate('/crm');
    } else if (e !== "" && e.target.value === 'contacts') {
      navigate('/crm/contacts');
    } else if (e !== "" && e.target.value === 'prospects') {
      navigate('/crm/prospects');
      //setContacts(null);
    }
    // else if (e !== "" && e.target.value === 'accounts') {
    //navigate to account in next phase
    //navigate('/crm/accounts');
    // }

  }

  useEffect(() => {
    if (contactsCP.length == 1) {
      if (contactsCP[0].type == "Lead") {
        setIsLead(true);
      } else if (contactsCP[0].type == "Prospect") {
        setIsProspect(true);
      } else if (contactsCP[0].type == "Contact") {
        setIsContact(true);
      }
    } else {
      setIsLead(false);
      setIsProspect(false);
      setIsContact(false);
    }
  }, [contactsCP]);

  const moveToAction = (e, val) => {
    let contactId = [];
    contactId = contactsCP.map(contact => {
      return contact.contact_id;
    });
    console.log("contacts", contactId);
    let body = {
      contact_id: contactId
    }
    if (val === 'prospects') {
      body['action'] = "convert_into_prospect";
      //console.log(contactId, props.contactsCP);
      dispatch(moveContactsProspectsLeads(authToken, body, pagename));
    } else if (val === 'leads') {
      body['action'] = "convert_into_lead";
      dispatch(moveContactsProspectsLeads(authToken, body, pagename));
    }
    props.setContacts([]);
  }

  return (
    <>
      <Row className="crm-search-row">
        <Col className="col-9 crm-col">
          {/* <Form> */}
          <Row>
            <Col className="col-2">
              <Form.Select aria-label="select-type" onChange={viewComponent}
                value={pagename}
              >
                <option value="leads">Leads</option>
                <option value="contacts">Contacts</option>
                <option value="prospects">Prospects</option>
                {/* <option value="accounts">Accounts</option> */}
              </Form.Select>
            </Col>
            {/* <Col className="col-2">
                <Form.Select aria-label="select-type">
                  <option>Pipeline Name</option>
                </Form.Select>
              </Col> */}
            <Col>
              <Form className="position-relative"
                onSubmit={(e) =>
                  searchSubmitHandler(e, searchRef.current.value, pagename)
                }
              >
                <div className={searchField ? "fixed-global-search" : ""}>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder={`Search ${pagename}`}
                    className="search-field"
                    ref={searchRef}
                    value={searchField}
                    onChange={(e) => {
                      setSearchField(e.target.value);
                    }}
                  />

                  {!isSearchActive ? (
                    <div className="search-icon">
                      <Icon.Search
                        onClick={(e) => {
                          searchSubmitHandler(e, searchRef.current.value, pagename);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="search-icon">
                      <Icon.X
                        onClick={(e) => {
                          searchSubmitHandler(e, null, pagename);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
          {/* </Form> */}
        </Col>
        <Col>
          {pagename === "contacts" && ( /*  || pagename === "prospects" */
            <Button
              variant="info"
              className="add-new-asset-btn"
              onClick={() => {
                dispatch(fetchContactDetails(null));
                navigate(`/crm/${pagename}/add-contact`);
              }}
            >
              <span className="crm-header-btn-icon">
                <Icon.Plus />
              </span>
              New Contact
            </Button>
          )}
          {(pagename === "leads" && (userType === "P" || userType === "S")) && (
            <>
              <Button
                variant="info"
                className="add-new-asset-btn"
                onClick={() => {
                  navigate("/crm/leads/import");
                }}
              >
                <span className="crm-header-btn-icon">
                  <Icon.FileEarmarkArrowDownFill />
                </span>
                Import
              </Button>
              <Button
                variant="info"
                className="add-new-asset-btn me-2"
                onClick={exportDoc}
              >
                <span className="crm-header-btn-icon">
                  <Icon.FileEarmarkArrowUpFill />
                </span>
                Export
              </Button>
            </>
          )}
        </Col>
      </Row>


      {pagename === "leads" && (
        <Container className="crm-list-filter-container">
          <Row>
            <Col>
              <Row>
                <Col xs={12} md={8} className="more-filters">
                  {/* <Select
                isClearable
                onChange={handleChangeProduct}
                options={productDropdown}
                placeholder="Products"
                className="select-box"
              /> */}
                  <Select
                    isClearable
                    onChange={(e) => setSource(e)}
                    options={sourceDropdown}
                    placeholder="Source"
                    className="select-box"
                  />
                  {/* <Select
                  isClearable
                  onChange={(e) => setCity(e)}
                  options={cityDropdown}
                  placeholder="City"
                  className="select-box"
                /> */}
                  <Form>
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        variant="light"
                        id="dropdown-autoclose-outside"
                      >
                        {time === 1
                          ? "Today"
                          : time === 2
                            ? "One Week"
                            : time === 3
                              ? "One Month"
                              : time === 4
                                ? "Custom Date"
                                : "Select Date Range"}
                        {!time && (
                          <span className="icon-time">
                            <Icon.Calendar2Week />
                          </span>
                        )}
                        {time && (
                          <span
                            className="clear-time"
                            onClick={() => clearDropdown()}
                          >
                            <Icon.X />
                          </span>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="m-2">
                          {/* <Dropdown.Item href="">
                          <Form.Group className="radio-btn" controlId="today"> */}
                          <Form.Check
                            type="radio"
                            label="Today"
                            name="chooseTime"
                            checked={time === 1 ? true : false}
                            onClick={() => { setTimeFilter(1); }}
                          />
                          {/*  </Form.Group>
                        </Dropdown.Item>
                        <Dropdown.Item href="">
                          <Form.Group className="radio-btn" controlId="oneweek"> */}
                          <Form.Check
                            type="radio"
                            label="One Week"
                            name="chooseTime"
                            checked={time === 2 ? true : false}
                            onClick={() => setTimeFilter(2)}
                          />
                          {/* </Form.Group>
                        </Dropdown.Item>
                        <Dropdown.Item href="">
                          <Form.Group className="radio-btn" controlId="onemonth"> */}
                          <Form.Check
                            type="radio"
                            label="One Month"
                            name="chooseTime"
                            checked={time === 3 ? true : false}
                            onClick={() => setTimeFilter(3)}
                          />
                          {/*  </Form.Group>
                        </Dropdown.Item>
                        <Dropdown.Item href="">
                          <Form.Group className="radio-btn" controlId="custom"> */}
                          <Form.Check
                            type="radio"
                            label="Custom Date"
                            name="chooseTime"
                            checked={customDate ? true : false}
                            onClick={() => setTimeFilter(4)}
                          />
                          {/* </Form.Group>
                        </Dropdown.Item> */}
                        </div>
                        {customDate == true && (
                          <>
                            <Dropdown.Item href="">
                              {/* <DtPicker
                                showTimeInput
                                onChange={setStartDate}
                                placeholder="Start Date"
                                clearBtn={true}
                              /> */}

                              <DatePicker
                                selected={startDate}
                                onChange={setStartDate}
                                placeholderText="Select Start Date"
                                dateFormat="MM/dd/yyyy"
                                shouldCloseOnSelect={true}
                              />
                            </Dropdown.Item>
                            <Dropdown.Item href="">
                              {/* <DtPicker
                                showTimeInput
                                onChange={setEndDate}
                                placeholder="End Date"
                                clearBtn={true}
                              /> */}

                              <DatePicker
                                selected={endDate}
                                onChange={setEndDate}
                                placeholderText="Select End Date"
                                dateFormat="MM/dd/yyyy"
                                shouldCloseOnSelect={true}
                              />

                            </Dropdown.Item>
                            {<Dropdown.Item href="">
                              <Button variant="info" onClick={handleChangeCustomDate} style={{ width: "100%" }}>
                                Filter By Custom Date
                              </Button>
                            </Dropdown.Item>}
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form>

                </Col>
                <Col className="sort-by">
                  <Select
                    isClearable
                    onChange={(e) => setSort(e)}
                    options={sortOptions}
                    placeholder="Sort by Date"
                    className="select-box"
                  />
                  {/* <Button variant="outline-info">Save View</Button> */}
                  <Dropdown className="setting-btn" autoClose="outside">
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-autoclose-outside"
                    >
                      <Icon.Gear />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/1">
                        Name
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          className="float-end"
                          checked
                        // label="Smart Folder"
                        //checked={smartFolderOptions}
                        //onChange={setSmartFolderOptionHandler}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item href="#/2">
                        Company Name
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          className="float-end"
                          defaultChecked={props.companyName}
                          onChange={() => props.settingsHandler("companyName")}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item href="#/3">
                        Last Engagement
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          className="float-end"
                          defaultChecked={props.lastEngagement}
                          onChange={() => props.settingsHandler("lastEngagement")}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item href="4">
                        Tasks
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          className="float-end"
                          defaultChecked={props.tasks}
                          onChange={() => props.settingsHandler("tasks")}
                        />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>

          {props.checked !== 0 && props.checked > 0 && (
            <Row>
              <Col>
                <div className="selectedAssetActions">
                  <ul>
                    <li>
                      <Button variant="light selected">
                        {props.checked} Selected
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="light"
                        onClick={(e) => {
                          doLeadAction(e, 'delete');
                        }}
                      >
                        <span>
                          <Icon.Trash />
                        </span>
                        &nbsp;Delete
                      </Button>
                    </li>
                    {/*  <li>
                  <Button variant="light">
                    <span>
                      <Icon.Plus />
                    </span>
                  </Button>
                </li> */}
                    <li>
                      <Button variant="light" onClick={(e) => doLeadAction(e, 'note')}>
                        <span>
                          <Icon.Sticky />
                        </span>
                        &nbsp;Add Note
                      </Button>
                    </li>
                    <li>
                      <Button variant="light" onClick={(e) => doLeadAction(e, 'mail')}>
                        <span>
                          <Icon.Envelope />
                        </span>
                        &nbsp;Send Mail
                      </Button>
                    </li>
                    {/* <li>
                  <Button variant="light">
                    <span>
                      <Icon.Telephone />
                    </span>
                  </Button>
                </li> */}
                    <li>
                      <Button variant="light" onClick={(e) => doLeadAction(e, 'task')}>
                        <span>
                          <Icon.ClipboardCheck />
                        </span>
                        &nbsp;Add Task
                      </Button>
                    </li>
                    {/* <li>
                  <Button variant="light">
                    <span>
                      <Icon.People />
                    </span>
                  </Button>
                </li> */}
                  </ul>
                  {/* <ul className="float-right">
                    <li>
                      <Button variant="light selected">Select All</Button>
                    </li>
                    <li>
                      <Button variant="light selected">Unselect</Button>
                    </li>
                  </ul> */}
                </div>
              </Col>
            </Row>
          )}
        </Container>
      )}

      {contactsCP.length > 0 && (pagename === 'contacts' || pagename === 'prospects') && (
        <Container>
          <Row className="mt-4">
            <Col>
              <div className="selectedAssetActions p-2">
                <ul>
                  <li>
                    <Button variant="light selected">
                      {contactsCP.length} Selected
                    </Button>
                  </li>
                  {pagename === 'contacts' && ((isContact == false && isProspect == false && isLead == false) || (isContact === true && isProspect == false && isLead == false)) && (
                    <li>
                      <Button
                        variant="light"
                        onClick={(e) => {
                          moveToAction(e, 'prospects');
                        }}
                      >
                        <div className="p-2 h-25 text-color fw-bold"> Move to Prospects</div>

                      </Button>
                    </li>
                  )}
                  {((isContact == false && isProspect == false && isLead == false) || (isContact === true || isProspect === true || isLead === false)) && (
                    <li>
                      <Button
                        variant="light"
                        onClick={(e) => {
                          moveToAction(e, 'leads');
                        }}
                      >
                        <div className="p-2 h-25 text-color fw-bold"> Move to Leads </div>
                      </Button>
                    </li>
                  )}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      )}


      <DeleteModal show={deleteDialog} hide={() => setDeleteDialog(false)} deleteItem={confirmDeleteLeads} />

      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end" className="offcanvas-section">
        <Offcanvas.Header> {/* closeButton */}
          {Leadlist.contact_ids && (<div className="container-body">
            <Stack direction="horizontal" gap={2} className="ml-5">
              {leadAction === 'note' && (
                <>
                  <Icon.Sticky
                    style={{
                      fontSize: 15,
                      color: '#45BCD2',
                    }}
                  />
                  <div className="fs-5 fw-bold text-color mb-2 p-2">Adding Note To</div>
                </>
              )}
              {leadAction === 'task' && (
                <>
                  <Icon.ClipboardCheck
                    style={{
                      fontSize: 15,
                      color: '#45BCD2',
                    }}
                  />
                  <div className="fs-5 fw-bold text-color mb-2 p-2">Adding Task To</div>
                </>
              )}
              {leadAction === 'mail' && (
                <>
                  <Icon.Envelope
                    style={{
                      fontSize: 15,
                      color: '#45BCD2',
                    }}
                  />
                  <div className="fs-5 fw-bold text-color mb-2 p-2">Sending Mail To</div>
                </>
              )}
            </Stack>
            <Row>
              {Leadlist.contact_ids.length <= 5 ?
                <Stack direction="horizontal" gap={1}>
                  {Leadlist.contact_ids.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className='circle1'>{getChars(item.user_name)}</div>
                        <div className='fs-6 fw-normal wordBreak'>{item.user_name}</div>
                      </div>
                    )
                  }
                  )}
                </Stack>
                :
                <div>
                  <Stack direction="horizontal">
                    <div>
                      <div className='circle1'>{getChars(Leadlist.contact_ids[0].user_name)}</div>
                      <div className='fs-6 fw-normal wordBreak'>{Leadlist.contact_ids[0].user_name}</div>
                    </div>
                    <div>
                      <div className='circle1'>{getChars(Leadlist.contact_ids[1].user_name)}</div>
                      <div className='fs-6 fw-normal wordBreak'>{Leadlist.contact_ids[1].user_name}</div>
                    </div>
                    <div>
                      <div className='circle1'>{getChars(Leadlist.contact_ids[2].user_name)}</div>
                      <div className='fs-6 fw-normal wordBreak'>{Leadlist.contact_ids[2].user_name}</div>
                    </div>
                    <div className="m-3">+{Leadlist.contact_ids.length - 3}</div>
                  </Stack>
                </div>
              }
            </Row>
          </div>
          )}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="p-4">
            {leadAction === 'note' && (
              <AddNote contactid={Leadlist} authToken={authToken} from="bulk" setDefault={() => handleOffcanvasClose()} />
            )}
            {leadAction === 'task' && (
              <AddTask contactId={Leadlist} authToken={authToken} from="bulk" setDefault={() => handleOffcanvasClose()} />
            )}
            {leadAction === 'mail' && (
              <AddMail contactId={Leadlist} authToken={authToken} from="bulk" setDefault={() => handleOffcanvasClose()} />
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>

    </>
  );
};

export default CRMListFilters;
