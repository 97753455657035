import React, { useState } from 'react';
import * as Icon from "react-bootstrap-icons";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "../asset-upload/fileupload.css";
import { setFilesAction, uploadFile, removeBroucher } from '../../../../redux/actions/actions';
import Toast from '../../../shared/Toast';

// drag drop file component
const FileUploadComponent = (props) => {
  // drag state
  const dispatch = useDispatch();
  // const [dragZone, setDragZone] = useState(false);
  const [theArray, setTheArray] = useState([]);
  const [actualArray, setActualArray] = useState([]);
  const [arrayCount, setArrayCount] = useState(0);
  const [dropLoader, setDropLoader] = useState(false);
  const [errorData, setErrorData] = useState(["","",""]);
  const uploadProgress = useSelector((state) => state.assetUpload.uploadProgress);
  // ref
  const inputRef = React.useRef(null);
  
  const fileSizeCalc = (number) => {
    let num = number / 1024;
    if (num < 999) {
      return `${Math.ceil(num)}Kb`;
    } else {
      return `${Math.ceil(num / 1024)}Mb`;
    }
  }
  
  useEffect(() => {
    dispatch({ type: "UPLOAD_LOADING", payload: false});
  }, [])
    
  const handleErrorData = (data, index) => {
    let a = errorData;
    a[index] = data;
    setErrorData(a);
  }

  useEffect(() => {
      props.handleBroucher(theArray);
      props.handleBroucher(theArray);
      setActualArray(theArray);
      console.log(theArray);
  }, [theArray])
  
  useEffect(() => {
    dispatch(setFilesAction(theArray));
  }, [arrayCount])
  
    

  const removedocument = async (objId) => {
    setDropLoader(true);
    dispatch(removeBroucher(objId));
    const newArray = [...theArray];
    const filteredArray = await newArray.filter((item, ind) => {
      return ind !== objId
    });
    setTheArray(filteredArray);  
  }
    
    // triggers when file is selected with click
  const handleChange = async function (e) {
        e.preventDefault();
        setDropLoader(true);
        if (e?.target?.files) {
          let a = e.target.files;
          const objArray = await Object.values(a);
          if (objArray?.length > 5 || (objArray?.length + theArray?.length) > 5) {
            setDropLoader(false);
            Toast("Maximum 5 Documents Allowed", "E");
            return;
          }
          let arrayData = [];
          objArray.map((file,file_index) => {
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                let doc_array;
                doc_array = {
                  base64: reader.result,
                  file: {
                      name: file.name,
                      size: file.size,
                      type: file.type
                  },
                }
                if (doc_array?.file?.size > 10240000) {
                  Toast(`"${doc_array?.file?.name}" file size should be smaller than 10 MB`,"E");
                  setDropLoader(false);
                  return;
                }
                let name = file.name.toUpperCase();
                let charNum = name.lastIndexOf(".");
                if (name.includes(".PDF")) {
                  setTheArray((prevArr) => [...prevArr, doc_array]);
                  arrayData.push(doc_array);
                  setArrayCount(prev => prev + 1);                  
                } else {
                  Toast(`${name.substring(charNum+1)} file not allowed`,"E");
                }
                return arrayData;
              };
          })
          inputRef.current.value = null;
        }
  };
  
  const handleScaning = () => {
    actualArray.map((file,f_ind) => {
      dispatch(uploadFile(props.authToken, file, f_ind, handleErrorData));
    })
  }
  
  return (
    <>
      {actualArray && actualArray?.length > 0 && <div className={'w-100 row d-flex justify-content-center'}>
        <label htmlFor="input-file-upload" className='file-upload' />
        {actualArray?.length > 0 && actualArray.map((item, index) => {
          return (
            <div className='position-relative my-div-bg box col-2 my-0 mx-2 customize-card'>
              {uploadProgress?.length > 0 && uploadProgress[index] == true ? <p className='overlay-broucher'></p>:""}
              {uploadProgress?.length > 0 && uploadProgress[index] == true ? <span class="spinner-border text-info overlay-spinner" role="status">
              </span>:""}
              <Icon.XLg className='position-absolute cross-pdf cursor-pointer' onClick={(e) => { removedocument(index) }} color='#ffffff' />
              {(uploadProgress?.length > 0 && (uploadProgress[index] === "complete" || uploadProgress[index] === "error")) && <span title={uploadProgress[index] != "complete" ? errorData[index]:""} className={`${uploadProgress[index] != "complete" ? "folder-tag":"folder-tag-2"} enhance-tag`}></span>}
              
              {/* <img className='w-[30px]' title={item.title + " " + fileSizeCalc(item?.doc_url?.file?.size)} src={imageSelector(item.doc_url?.file?.name)} alt="doc" /> */}
                <p className='mt-4 px-1'>{item.file?.name?.slice(0, 35) + "..."}</p>
                <p className=''>Size:{fileSizeCalc(item?.file?.size)}</p>
            </div>
          )
        })}
      </div>
      }  
             
      <form id="form-file-upload" className={'file-upload-parent'} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} accept=".pdf" type="file" id="input-file-upload" className='d-none' multiple={true} onChange={handleChange} />
        <div>
          <div>
              <label htmlFor="input-file-upload" className='mx-2'>
              <span className="attach-button">Attach</span>
              </label>
              <span onClick={handleScaning} className="attach-button mx-2">Scan</span>
          </div>
        </div> 
      </form>
      </>
  );
};

export default FileUploadComponent;